$(window).on('load', function(e){

    var intervalTimeInSeconds = 5; // Setting the time interval at which the function will try to reload, in seconds.
	var timer = setInterval(loadHotJarId, intervalTimeInSeconds * 1000);
	var hotjarSessionId = '';
    var counter = 0; // Setting a counter, so the function would stop retrying after the 6th time.

    /*  
     * The function tries to load the hotjar session id, and push it back to the GTM for tracking purposes.
     */
	function loadHotJarId() {

            if( counter == 0) {
                console.info('Loading Hotjar Session ID...')
            } else {
                console.warn('Retrying to load Hotjar Session ID...');
            }

            try {         
                hotjarSessionId = (hj.pageVisit.property.get('userId') || hj.globals.get("userId")).split("-").shift(); 
			    dataLayer.push({'event': 'hotjarSessionId', 'hotjarSessionId': hotjarSessionId });
                sendHotJarIdToWA(hotjarSessionId);
			    console.info('Hotjar session ID loaded successfully!'); 

			    clearInterval(timer); // If the ID is loaded successfully, it clears the "repeater/interval" for the function.
		    } catch (e) {        
			    hotjarSessionId = "N/A";
                if( counter >= 6) {
                    console.error('Hotjar Session ID Could not be loaded!');
                    clearInterval(timer);  // After the 6th try, it stops re-trying to get the Hotjar Session Id.
                }
                counter++;    
		    }
    }

    function sendHotJarIdToWA(hotjarSessionId) {
	    var params = "hotjar="+hotjarSessionId;
        var xmlhttp = new XMLHttpRequest();   // new HttpRequest instance
        xmlhttp.open("POST", document.location.origin+"/ext/hotjar");
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.send(params);
    }
    
});